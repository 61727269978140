<template>
  <div
    v-editable="blok"
    class="cta-dropdown"
    :class="[islighttheme ? 'cta-dropdown--light' : '']"
  >
    <button
      :id="blok.id"
      class="cta-dropdown__button"
      :class="[isMenuOpen ? 'cta-dropdown__button--open' : '']"
      :aria-expanded="isMenuOpen"
      :aria-controls="`cta-dropdown-${blok._uid}`"
      @click="toggleMenu"
    >
      {{ blok.display_name }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="10"
        viewBox="0 0 16 10"
      >
        <path
          fill="#fff"
          d="M0 1.47L1.37.1l6.27 6.27L13.9.1l1.37 1.36L7.64 9.1z"
        />
      </svg>
    </button>
    <div
      :id="`cta-dropdown-${blok._uid}`"
      class="cta-dropdown__menu"
      :class="[
        isMenuOpen ? 'cta-dropdown__menu--open' : '',
        align === 'left'
          ? 'cta-dropdown__menu--left-aligned'
          : 'cta-dropdown__menu--right-aligned',
      ]"
    >
      <ul
        class="cta-dropdown__list"
        :class="blok.link_icons && 'cta-dropdown__list--no-icons'"
      >
        <li v-for="item in blok.links" :key="item._uid">
          <p v-if="item.eyebrow" class="eyebrow">
            {{ item.eyebrow }}
          </p>
          <NuxtLink
            v-if="item.link.cached_url"
            :id="'btn-' + (item.id ? item.id : item._uid)"
            :to="
              item.link.linktype === 'url'
                ? item.link.cached_url
                : '/' + item.link.cached_url
            "
            :rel="
              item.link.target === '_blank' ? 'nofollow noopener' : undefined
            "
            :target="item.link.target === '_blank' ? item.link.target : ''"
            :class="item.preload ? item.preload : ''"
            class="cta-dropdown__link"
          >
            <span>{{ item.display_name }}</span>
            <svg
              v-if="
                !blok.link_icons &&
                item.display_name.toLowerCase().includes('download')
              "
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 14.166c-.458 0-.851-.163-1.177-.489s-.49-.719-.49-1.177V10H2v2.5h10V10h1.667v2.5c0 .458-.163.851-.49 1.177s-.719.489-1.177.489H2zm5-3.333L2.834 6.666 4 5.458l2.167 2.167V.833h1.667v6.792L10 5.458l1.167 1.208L7 10.833z"
                fill="#1d2227"
              />
            </svg>
            <svg
              v-if="
                !blok.link_icons &&
                item.display_name.toLowerCase().includes('schedule')
              "
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.104 9.208L13.834 4.5l-1.187-1.187-3.542 3.542-1.771-1.771L6.167 6.25l2.937 2.958zM.833 15v-1.667h18.333V15H.833zm2.5-2.5c-.458 0-.851-.163-1.177-.49s-.49-.719-.49-1.177V1.667c0-.458.163-.851.49-1.177S2.875 0 3.334 0h13.333c.458 0 .851.163 1.177.49s.49.719.49 1.177v9.167c0 .458-.163.851-.49 1.177s-.719.49-1.177.49H3.334zm0-1.667h13.333V1.667H3.334v9.167z"
                fill="#1d2227"
              />
            </svg>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CTADropdownBlokProps, CTADropdownProps } from '@/types';

// Props
withDefaults(
  defineProps<{
    align?: CTADropdownProps['align'];
    blok: CTADropdownBlokProps;
    islighttheme?: boolean;
  }>(),
  {
    align: 'left',
    islighttheme: false,
  }
);

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
</script>

<style lang="scss">
.cta-dropdown {
  position: relative;
  width: fit-content;

  &--light {
    --lightest: var(--dark-gray);
    --darkest: var(--white);
  }

  .eyebrow {
    color: var(--highlight);
    font-family: unset;
    font-size: 0.875rem;
    margin-block-end: 10px;
  }

  &__link {
    display: grid;
    font-family: var(--mint-extra-bold), -apple-system, system-ui,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      sans-serif;
    gap: 20px;
    grid-template-columns: 1fr 20px;
    letter-spacing: -0.03em;
    line-height: 94%;
    text-decoration: none;

    .cta-dropdown__list--no-icons & {
      grid-template-columns: unset;
    }

    span {
      font-size: inherit;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @media all and #{$mq-m-min} {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }

    svg {
      display: inline;
      width: 18px;

      path {
        fill: var(--darkest);
      }
    }

    &:focus-visible {
      outline: 1px solid var(--dark-gray);
      outline-color: var(--darkest);
      outline-offset: 4px;
    }
  }

  &__button {
    align-items: center;
    border: 1px solid var(--lightest);
    border-radius: 2px;
    color: var(--darkest);
    background-color: var(--lightest);
    display: flex;
    font-size: 0.875rem;
    justify-content: center;
    outline-offset: 4px;
    padding-block: 12px;
    padding-inline: 16px;
    text-align: center;
    text-decoration: none;

    svg {
      margin-inline-start: 8px;
      width: 9px;

      path {
        fill: var(--darkest);
      }
    }

    /* @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--lightest);
        color: var(--darkest);

        svg path {
          fill: var(--darkest);
        }
      }
    }*/

    &--open {
      background-color: var(--lightest);
      color: var(--darkest);

      svg {
        transform: rotate(180deg);

        path {
          fill: var(--darkest);
        }
      }
    }

    &:focus-visible {
      background-color: var(--lightest);
      color: var(--darkest);
      outline: 1px solid var(--lightest);

      svg path {
        fill: var(--darkest);
      }
    }
  }

  &__menu {
    background-color: var(--lightest);
    color: var(--darkest);
    display: flex;
    flex-direction: column;
    inset-block-start: calc(100% + 8px);
    inset-inline-end: 0;
    opacity: 0;
    position: absolute;
    top: calc(100% + 4px);
    transition: background-color var(--timing) var(--easing-1),
      opacity var(--timing) var(--easing-1),
      transform var(--timing) var(--easing-1),
      visibility var(--timing) var(--easing-1);
    visibility: hidden;
    width: max-content;
    z-index: 10;

    &--open {
      opacity: 1;
      visibility: visible;
    }

    &--left-aligned {
      border-radius: 2px 16px 16px;
      inset-inline-start: 0;
    }

    &--right-aligned {
      border-radius: 16px 2px 16px 16px;
      inset-inline-end: 0;
    }
  }

  &__logo {
    &:focus-visible {
      outline: 1px solid var(--dark-gray);
      outline-offset: 4px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-block: 0;
    margin-inline: 0;
    padding-block: 18px 20px;
    padding-inline: 20px;

    li ~ li {
      padding-block-start: 5px;
    }
  }
}
</style>
